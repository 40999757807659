import authenticatedRequest from '../../lib/authenticatedRequest'
import unauthenticatedRequest from '../../lib/unauthenticatedRequest'
import { saveNewToken } from '../../lib/auth'
import { myBrandReports } from './brandReports'

export const ActionTypes = {
  SET_SIGNED_IN: 'SET_SIGNED_IN',
  SET_USER: 'SET_USER',
  SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
}

export function resetUser() {
  return dispatch => {
    return authenticatedRequest({
      extension: '/user/me',
      responseType: 'json',
      method: 'get',
    }).then(res => {
      dispatch({
        type: ActionTypes.SET_USER,
        payload: res.data.user,
      })
    })
  }
}

// best way to actually manage the user? If there is no redux user, we can refetch I suppose...
export function signIn({ email, password }) {
  return async dispatch => {
    try {
      const res = await unauthenticatedRequest({
        extension: '/signin',
        responseType: 'json',
        method: 'post',
        // body: {},
        data: {
          email,
          password,
        },
      })

      saveNewToken(res.data.token)
      dispatch(myBrandReports())
      dispatch({
        type: ActionTypes.SET_USER,
        payload: res.data.user,
      })
    } catch (e) {
      dispatch({
        type: ActionTypes.SET_LOGIN_ERROR,
        payload: e.status === 401 ? 'Incorrect username or password' : 'Issue logging in',
      })
    }
  }
}
