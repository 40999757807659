import queryString from 'query-string'
import storage from './storage'
import unauthenticatedRequest from './unauthenticatedRequest'

const GUEST_TOKEN_PREFIX = 'GUEST-'

export function isSignedIn() {
  if (typeof window === 'undefined') {
    return false
  }

  const token = storage.get('token')

  const currentTokenGuest =
    token && token.slice(0, GUEST_TOKEN_PREFIX.length) === GUEST_TOKEN_PREFIX

  const queryParams = queryString.parse(window.location.search)

  let signedIn = false

  // override guest tokens with new route tokens, but not normal tokens
  if ((!token || currentTokenGuest) && queryParams.token) {
    saveNewToken(queryParams.token)
    signedIn = true
  }

  // if token exists return true
  if (token !== undefined && token !== 'undefined') {
    signedIn = true
  }

  return signedIn
}

export function saveNewToken(token) {
  storage.set('token', token, true)
}

export function signIn({ email, password }) {
  return unauthenticatedRequest({
    extension: '/api/signin',
    responseType: 'json',
    method: 'post',
  }).then(res => {
    saveNewToken(res.body.token)
    return res.user
  })
}

export function logout() {
  storage.remove('token')
  window.location.href = '/login'
}
