import { combineReducers } from 'redux'
import ModalReducer from './modals'
import UserReducer from './user'
import BrandReportsReducer from './brandReports'

const rootReducer = combineReducers({
  modals: ModalReducer,
  user: UserReducer,
  brandReports: BrandReportsReducer,
})

export default rootReducer
