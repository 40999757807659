import _ from 'lodash'
import { ActionTypes } from '../actions'

const defaultState = {
  surveyId: null,
  surveys: [],
  highlights: [],
  trackInfogram: null,
  competitorsInfogram: null,
  sentimentInfogram: null,
}

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_BRAND_REPORTS:
      return Object.assign({}, state, {
        trackInfogram: action.payload[0].trackInfogram,
        competitorsInfogram: action.payload[0].competitorsInfogram,
        sentimentInfogram: action.payload[0].sentimentInfogram,
      })
    case ActionTypes.SET_BRAND_REPORT: {
      const {
        trackInfogram,
        competitorsInfogram,
        sentimentInfogram,
        surveys,
        survey: { highlights, _id },
      } = action.payload
      return Object.assign({}, state, {
        surveyId: _id,
        highlights,
        surveys: _.sortBy(surveys, 'publishedAt').reverse(),
        trackInfogram,
        competitorsInfogram,
        sentimentInfogram,
      })
    }
    default:
      return state
  }
}

export default UserReducer
