import axios from 'axios'
import storage from './storage'
import prefix from './prefix'
import urlJoin from 'url-join'

export default function (options) {
  options.headers = {
    ...(options.headers || {}),
    'X-Access-Token': storage.get('token'),
  }

  if (options.extension && !options.uri) {
    options.url = urlJoin(prefix, options.extension)
  }

  return axios(options)
}
