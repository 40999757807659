import React from 'react'
import { Provider } from 'react-redux'

import createStore from './src/redux/createStore'
import { resetUser } from './src/redux/actions/user'
import { myBrandReports } from './src/redux/actions/brandReports'
import { isSignedIn } from './src/lib/auth'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, wayup }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()

  if (wayup !== 'true' && isSignedIn()) {
    store.dispatch(resetUser())
    store.dispatch(myBrandReports())
  }

  return <Provider store={store}>{element}</Provider>
}
