import { ActionTypes } from '../actions'

const defaultState = {
  calendarModal: { isOpen: false },
  hubspotModal: { isOpen: false },
}

const CreateViewModalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL_STATE:
      return Object.assign({}, state, {
        [action.payload.modal]: {
          ...(state[action.payload.modal] || {}),
          isOpen: action.payload.isOpen,
          allowClose: action.payload.allowClose,
          trigger: action.payload.trigger,
        },
      })
    case ActionTypes.TOGGLE_MODAL_STATE:
      return Object.assign({}, state, {
        [action.payload.modal]: {
          ...(state[action.payload.modal] || {}),
          isOpen: !(state[action.payload.modal] || {}).isOpen,
          ...(action.payload.customData || {}),
        },
      })
    default:
      return state
  }
}

export default CreateViewModalReducer
