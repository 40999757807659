import mixpanel from 'mixpanel-browser'

export const ActionTypes = {
  SET_MODAL_STATE: 'SET_MODAL_STATE',
  TOGGLE_MODAL_STATE: 'TOGGLE_MODAL_STATE',
}

export function showGuestModal(options) {
  const { allowClose, trigger } = options || {}
  mixpanel.track('Guest Modal Opened', { trigger: trigger })
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_MODAL_STATE,
      payload: {
        modal: 'guestModal',
        isOpen: true,
        allowClose,
        trigger,
      },
    })
  }
}

export function hideGuestModal() {
  mixpanel.track('Guest Modal Closed')
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_MODAL_STATE,
      payload: {
        modal: 'guestModal',
        isOpen: false,
      },
    })
  }
}

export function toggleCrossTabModal() {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_MODAL_STATE,
      payload: {
        modal: 'crossTabModal',
      },
    })
  }
}

export function toggleCalendarModal() {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_MODAL_STATE,
      payload: {
        modal: 'calendarModal',
      },
    })
  }
}

export const toggleHubspotModalResearchConsultation = () =>
  toggleHubspotModal('researchConsultation')
export const toggleHubspotModalReportSubscription = () => toggleHubspotModal('reportSubscription')
export const toggleHubspotModalSolutions = () => toggleHubspotModal('solutions')
export const toggleHubspotModalRequestQuote = () => toggleHubspotModal('requestQuote')

export function toggleHubspotModal(hubspotForm) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_MODAL_STATE,
      payload: {
        modal: 'hubspotModal',
        customData: {
          hubspotForm,
        },
      },
    })
  }
}
